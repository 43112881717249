import React from "react"
import styled from "styled-components"
import Layout, { MaxWidthContainer } from "../components/layout"
import SEO from "../components/seo"
import { colors } from "../styles/colors"

const Header = styled.section`
    background: ${colors.base};
    color: white;
    padding: 12vh 20px;
`
const Content = styled(MaxWidthContainer)`
    max-width: 800px;

    ul {
        max-width: 600px;
    }

    p {
        max-width: 800px;
    }
`
const Title = styled.h1`
    text-transform: uppercase;
    font-size: 55px;
`
const Copy = styled.p`
    font-size: 20px;
    line-height: 1.4;
    max-width: 600px;
`
const Body = styled.section`
    padding: 12vh 20px;
`

const CovidPolicyPage = () => {
    return <div />
    // return (
    //     <Layout>
    //         <SEO title="COVID-19 Policy" />
    //         <Header>
    //             <Content>
    //                 <Title>{covid19policy.pageTitle}</Title>
    //                 <Copy>{covid19policy.pageDescription}</Copy>
    //             </Content>
    //         </Header>
    //         <Body>
    //             <Content dangerouslySetInnerHTML={{ __html: content }} />
    //         </Body>
    //     </Layout>
    // )
}

export default CovidPolicyPage
